import { mq, variables } from "../../../cssInJs";

export default {
  pageTiles: mq({
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    margin: "2rem -10px",
    overflow: "hidden",
    marginLeft: [
      "0",
      null,
      "-2rem",
      "-1rem",
      "-3rem",
      "-5rem",
      "-6rem",
      "-8rem",
      "-10rem",
      "-12rem",
    ],
    marginRight: [
      "0",
      null,
      "-2rem",
      "-1rem",
      "-3rem",
      "-5rem",
      "-6rem",
      "-8rem",
      "-10rem",
      "-12rem",
    ],
  }),
  pageTile__tile: mq({
    flex: "0 0 100%",
    maxWidth: "100%",
    // padding: "10px",
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
    zIndex: 1,
    //backgroundColor: 'rgba(255,255,255,0.2)'
    "button.slick-arrow": {
      display: "none !important",
    },
  }),
  tile__content: {
    //position: 'relative'
    "&:hover": {
      textDecoration: "none",
    },
  },
  tile__container: mq({
    zIndex: "-1",
    display: "flex",
    flexDirection: "row",
    //justifyContent: 'space-between',
    width: "100%",
    height: [
      "auto",
      null,
      "270px",
      "348px",
      "427px",
      "443px",
      "489px",
      "519px",
      "549px",
      "579px",
    ],
    //cursor: 'pointer',
    position: "relative",
    flexWrap: "wrap",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    "&.odd__container": mq({
      //backgroundImage: ['unset', null, 'url("/assets/tile-1.png")']
    }),
    "&.even__container": mq({
      //backgroundImage: ['unset', null, 'url("/assets/tile-2.png")']
    }),
    "&.red": {
      backgroundColor: `rgba(198, 16, 23, 0.15)`,
    },
    "&.orange": {
      backgroundColor: `rgba(241, 90, 41, 0.15)`,
    },
    "&.yellow": {
      backgroundColor: `rgba(247, 148, 29, 0.15)`,
    },
    "&.green": {
      backgroundColor: `rgba(57, 181, 74, 0.15)`,
    },
    "&.aqua": {
      backgroundColor: `rgba(0, 167, 157, 0.15)`,
    },
    "&.blue": {
      backgroundColor: `rgba(10, 124, 201, 0.15)`,
    },
    "&.purple": {
      backgroundColor: `rgba(104, 50, 173, 0.15)`,
    },
    "&.violet": {
      backgroundColor: `rgba(146, 39, 143, 0.15)`,
    },
  }),
  tile__bg: mq({
    // width: '55%',
    // left: '8%',
    // top:'6%',
    position: ["static", null, "absolute"],
    width: ["100%", null, "62%"],
    left: "2%",
    top: "2%",

    "img": {
      width: "100%",
    },
  }),
  tile__contents: mq({
    position: ["static", null, "absolute"],
    width: ["100%", null, "30%"],
    right: "4%",
    top: "50%",
    color: "white",
    fontSize: ["0.8rem", null, "0.6rem", "0.8rem"],
    marginTop: ["1rem", "0px"],
    paddingLeft: ["1rem", null, "0px"],
    paddingRight: ["1rem", null, "0px"],
    "&[data-aos^='fade'][data-aos^='fade'].aos-animate": mq({
      //transform: 'translate3d(0, 0, 0) translateY(-50%)'
      transform: [
        "translate3d(0, 0, 0)",
        null,
        "translate3d(0, 0, 0) translateY(-50%)",
      ],
    }),
  }),
  tile__contents__title: mq({
    fontSize: ["1.6rem", null, "1.3rem", "1.6rem"],
    lineHeight: "1",
    fontWeight: "400",
    "&.red": {
      color: variables.redColor,
    },
    "&.orange": {
      color: variables.orangeColor,
    },
    "&.yellow": {
      color: variables.yellowColor,
    },
    "&.green": {
      color: variables.greenColor,
    },
    "&.aqua": {
      color: variables.aquaColor,
    },
    "&.blue": {
      color: variables.blueColor,
    },
    "&.purple": {
      color: variables.purpleColor,
    },
    "&.violet": {
      color: variables.violetColor,
    },
  }),
  tile__contents__small_dec: {
    fontWeight: "400",
    marginTop: "0.8rem",
  },
  tile__contents__dec: {
    marginTop: "0.8rem",
  },
  tile__contents__plus: mq({
    position: "absolute",
    bottom: ["0px", null, "6px"],
    right: "0px",
    width: ["50px", null, "97px"],
    "img": {
      width: "100%",
    },
  }),
  tile__icons: {
    marginTop: "0.8rem",
    //display: 'flex',
    //flexWrap: 'wrap',
    //flexDirection: 'column',
  },
  tile__icons__item: {
    width: "30px",
    marginTop: "0.1rem",
    marginBottom: "0.1rem",
    "img": {
      width: "100%",
    },
  },

  dotPointColor: {
    "&.dotPointColor__red": {
      color: variables.redColor,
    },
    "&.dotPointColor__yellow": {
      color: variables.yellowColor,
    },
    "&.dotPointColor__green": {
      color: variables.greenColor,
    },
    "&.dotPointColor__orange": {
      color: variables.orangeColor,
    },
    "&.dotPointColor__purple": {
      color: variables.purpleColor,
    },
    "&.dotPointColor__indigo": {
      color: variables.violetColor,
    },
    "&.dotPointColor__teal": {
      color: variables.aquaColor,
    },
  },

  tile__bg_even: mq({
    // width: '55%',
    // right: '9%',
    // top:'6%',
    position: ["static", null, "absolute"],
    width: ["100%", null, "62%"],
    right: "2%",
    top: "2%",
    "img": {
      width: "100%",
    },
  }),
  tile__contents_even: mq({
    position: ["static", null, "absolute"],
    width: ["100%", null, "30%"],
    left: "8%",
    top: "50%",
    color: "white",
    fontSize: ["0.8rem", null, "0.6rem", "0.8rem"],
    marginTop: ["1rem", "0px"],
    paddingLeft: ["1rem", null, "0px"],
    paddingRight: ["1rem", null, "0px"],
    "&[data-aos^='fade'][data-aos^='fade'].aos-animate": mq({
      transform: [
        "translate3d(0, 0, 0)",
        null,
        "translate3d(0, 0, 0) translateY(-50%)",
      ],
    }),
  }),
  tile__contents__plus_even: mq({
    position: "absolute",
    bottom: ["0px", null, "4px"],
    left: ["unset", null, "3px"],
    right: ["0px", null, "unset"],
    transform: ["rotate(0deg)", null, "rotate(90deg)"],
    width: ["50px", null, "97px"],
    "img": {
      width: "100%",
    },
  }),
};
